import { Box, Button, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import CButton from "../utils/CButton";
import CAlert from "../utils/CAlert";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { uploadFile, deleteFile, cartbiUpload, cartbiSave } from "../../services/studentService";
import CInput from "../utils/CInput";
import { fetchAccountAgg, poll, saveBankData } from "../../services/bankService";
import { getUserData } from "../../actions/getUserData";
import { setAppLoader } from "../../actions/setAppLoader";
import { saveFormPath } from "../../services/userService";
import CBankData from "../utils/CBankData";
import CDialogue from "../utils/CDialogue";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import success from "../../assets/images/Vector.svg";

export default function BankStatement({ setTabValue, setStepperCount, userDetailId }) {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const inputRef = React.useRef(null);
    const location = useLocation();
    const intervalIdRef = React.useRef(null);

    const [error, setError] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('');
    const [fileURL, setFileURL] = React.useState('')
    const [fileName, setFileName] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [bankDetails, setBankDetails] = React.useState([])
    const [fileObj, setFileObj] = React.useState(null)
    const [detailId, setDetailId] = React.useState('')
    const [counter, setCounter] = React.useState(30);
    const [isStatementProcessing, setIsStatementProcessing] = React.useState(false);
    const [showPopUp, setShowPopUp] = React.useState(false);
    const [isFileUpload, setIsFileUpload] = React.useState(true);
    const [disableAA, setDisableAA] = React.useState(false);
    const [disableManual, setDisableManual] = React.useState(false);
    const [showAASuccess, setShowAASuccess] = React.useState(false);
    const [showManualSuccess, setShowManualSuccess] = React.useState(false);
    const [message, setMessage] = React.useState("Please enter password for the pdf.");
    const [disabledSubmit, setDisabledSubmit] = React.useState(true);
    const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));




    const userDetails = useSelector((state) => state?.getUserData?.data);
    const isFetching = useSelector((state) => state?.getUserData?.isFetching);
    const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
    const isCurrentAddress = userDetails?.aadharDetails?.isCurrentAddress
    const userId = userData?.userId;

    console.log(bankDetails)

    React.useEffect(() => {
        dispatch(getUserData(userDetailId))
    }, []);

    //Show Loader while fetching API
    React.useEffect(() => {
        const { ecres, resdate, fi } = getQueryParams();
        if (ecres && resdate && fi) 
            dispatch(setAppLoader(true))
        else
        dispatch(setAppLoader(isFetching))
    }, [isFetching])

    // Function to parse the query parameters
    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        return {
            ecres: searchParams.get("ecres"),
            resdate: searchParams.get("resdate"),
            fi: searchParams.get("fi")
        };
    };

    // Polling API call
    const pollApi = async () => {
        try {

            const response = await poll(localStorage.getItem('detailId'))
            // const response = {
            //     "consentStatus": "Accepted",
            //     "source": "AA",
            //     "novelPatternStatus": "Processed"
            // }

            const { consentStatus, source, novelPatternStatus } = response;
            if (consentStatus === 'REJECTED' && source === "AA") {
                //Stop Polling as the user has rejected consent and source was AA
                console.log(`Polling stopped. Consent Status: ${consentStatus}`);
                //setDisableAA(true)
                clearInterval(intervalIdRef.current);  // Stop polling
                setShowPopUp(true)
                setMessage("We could not fetch your details from Account Aggregator, kindly upload 6 month bank statement.");
                // Delay hiding the loader until after the pop-up is visible
                setTimeout(() => {
                    dispatch(setAppLoader(false));
                }, 200);
            }
            else if (novelPatternStatus === "Submit") {
                //Poll Again irrespective of source as the statement is not processed yet
                console.log("Polling again in 5 seconds...");
            } else if (novelPatternStatus === "Processed" || novelPatternStatus === "Downloaded") {
                //Stop Polling irrespective of source as the statement is processed or in progress
                console.log(`Polling stopped. Status: ${novelPatternStatus}`);
                setDisableAA(true)
                setDisableManual(true)
                setBankDetails(response)
                clearInterval(intervalIdRef.current);  // Stop polling
                if (source === "AA")
                    setShowAASuccess(true)
                else {
                    setShowAASuccess(false)
                    onSubmitBankStatement()
                }

                setDisabledSubmit(false)
                // // Delay hiding the loader until after the pop-up is visible
                setTimeout(() => {
                    dispatch(setAppLoader(false));
                }, 2000);
            } else if (novelPatternStatus === "Rejected" && source === "Manual") {
                clearInterval(intervalIdRef.current);   // Stop polling
                setMessage("Uploaded bank statement can’t be processed. Please make sure you have received the PDF from your bank and try again");
                setShowPopUp(true)
                // Delay hiding the loader until after the pop-up is visible
                setTimeout(() => {
                    dispatch(setAppLoader(false));
                }, 2000);
            } else if (novelPatternStatus === "Rejected" && source === "AA") {
                setDisableAA(true)
                setMessage("We could not fetch your details from Account Aggregator, kindly upload 6 month bank statement.");
                setShowPopUp(true)
                clearInterval(intervalIdRef.current);
                // Delay hiding the loader until after the pop-up is visible
                setTimeout(() => {
                    dispatch(setAppLoader(false));
                }, 2000);   // Stop polling
            } else if (novelPatternStatus === null) {
                console.log("Null status, Polling again in 5 seconds...");
            }

        } catch (error) {
            console.error("Error calling the polling API:", error);
        }
    };

    // Start polling if query params exist
    React.useEffect(() => {
        dispatch(setAppLoader(true))
        const { ecres, resdate, fi } = getQueryParams();

        if (ecres && resdate && fi && !disableAA && !disableManual) {
            intervalIdRef.current = setInterval(pollApi, 5000);  // Poll every 5 seconds
        }

        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);  // Cleanup on unmount
            }
        };
    }, [location.search]);


    // // Call bank API after every 10 second
    // React.useEffect(() => {
    //     if (isStatementProcessing) {
    //         let res = ''
    //         let interval = setInterval(async () => {
    //             res = await cartbiSave(userDetails?.detailId)
    //         }, 10000);
    //         if (res && res?.status !== "In Progress") {
    //             clearInterval(interval);
    //             setBankDetails(res?.data?.data[0])
    //             dispatch(setAppLoader(false))
    //         }
    //         if (counter === 0) {
    //             clearInterval(interval);
    //         }
    //     }
    // }, [isStatementProcessing]);


    // SetData if there
    React.useEffect(() => {
        if (userDetails && userDetails?.userBankStatementDetails) {
            const bankStatement = userDetails?.userBankStatementDetails;
            setFileURL(bankStatement?.statementDetailPathId)
            setPassword(bankStatement?.statementDetailPassword)
            const fName = bankStatement?.statementDetailPathId?.split('/')
            setFileName(fName && fName[4])
            setDetailId(bankStatement?.statementDetailId)
            if (bankStatement?.statementDetailPathId) {
                setDisableAA(true)
                setDisableManual(true)
                setDisabledSubmit(false)
                if (userDetails?.userBankStatementDetails.source === "AA")
                    setShowAASuccess(true)
                else if (userDetails?.userBankStatementDetails.source === "Manual")
                    setShowManualSuccess(true)
            }
        }
    }, [userDetails])

    // React.useEffect(() => {
    //    if(fileObj)
    //         handleUploadFile()

    // }, [fileObj])

    function onFileUpload() {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    function onPasswordChange(e) {
        console.log(e.target.value)
        setPassword(e.target.value)
        if (fileObj)
            handleUploadFile(fileObj, e.target.value)
    };

    // On File Upload
    function handleFileChange(event) {
        event.preventDefault()
        const file = event.target.files && event.target.files[0];
        const extension = file.name.split('.')[1].toLowerCase();
        if (extension !== 'pdf') {
            setError(true)
            setErrorMsg("Only PDF files are allowed")
            return;
        }
        setFileObj(file)
        setIsFileUpload(false)
        setFileName(file?.name)

        handleUploadFile(file, password);
    }

    async function handleUploadFile(file, password) {
        dispatch(setAppLoader(true))
        const formData = new FormData();
        formData.append("file", file);
        formData.append("delimeter", userData?.userName)
        formData.append("detailId", +localStorage.getItem('detailId'))
        await uploadFile(formData).then(res => {
            if (res?.status === 200) {
                setFileName(file?.name)
                setFileURL(res?.data)
            }
        }).catch(err => {
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
        })
        const cartBiFormData = new FormData();
        cartBiFormData.append("file", file);
        cartBiFormData.append("id", +localStorage.getItem('detailId'))
        cartBiFormData.append("password", password)
        cartBiFormData.append("source", "Manual")

        await cartbiUpload(cartBiFormData).then(res => {
            if (res?.status === 200) {
                setIsFileUpload(true)
                setDetailId(res?.data?.statementDetailId)
                intervalIdRef.current = setInterval(pollApi, 5000);

            } else if (res?.status === 400 && res.data.status === "Rejected" && res.data.message.includes("Incorrect Password")) {
                setMessage("Please enter correct password for the pdf.")
                setShowPopUp(true)
            }
        }).catch(err => {
            console.log(err)
            setError(true)
            setErrorMsg("File length should be less than 15Mb")
        })
       dispatch(setAppLoader(false))
    }
    // Delete uploaded file by fileURL
    async function deleteFileByURL() {
        await deleteFile(fileURL)
        setFileURL('')
        setFileName('')
        setFileObj(null)
        setIsFileUpload(false)
        setPassword('')
    }


    // On submit 
    async function onSubmitBankStatement() {
        // e.preventDefault();
        setError(false)
        setErrorMsg('')
        const obj = {
            statementDetailId: detailId,
            statementDetailPathId: fileURL,
            statementDetailPassword: password ? password : null,
            userInstituteDetailId: userDetails?.detailId,
            statementDetailCreatedBy: userDetails?.user?.userName
        }
        const successBankData = await saveBankData(obj)
        //const bankData = await cartbiSave(userDetails?.detailId)

        // if bank data is under processs it will return from here 
        // if (bankData.status === 200) {
        //     if (bankData?.data?.status === "In Progress") {
        //         setIsStatementProcessing(true)
        //         return;
        //     } else if (bankData?.data?.statementAge > 30) {
        //         setError(true)
        //         setErrorMsg('Statement can not be older than 30 Days. Please upload a recent statement')
        //         return
        //     } else {
        //         setBankDetails(bankData?.data?.data[0])
        //     }
        // } else {
        //     const errorMessage = bankData?.response?.data?.message
        //     setError(true)
        //     setErrorMsg(errorMessage)
        //     return;
        // }
        if (successBankData.status === 200 || successBankData.status === 201) {
            setErrorMsg("Data Saved Successfully")
            dispatch(getUserData(userDetailId))
        } else {
            setError(true)
            setErrorMsg(successBankData?.response?.data?.message)
        }

    }

    function onFormSubmit(e) {
        e.preventDefault();
        onSubmit()
    }

    async function onSubmit() {
        console.log(userDetails?.detailId)
        await saveFormPath("/faceMatch", userDetails?.detailId)
        setTabValue(9);
        setStepperCount(6);
        navigate("/faceMatch", { state: { tabValue: 9, stepperCount: 6, userDetailId: userDetailId } })
    }

    function handleBack() {
        isCurrentAddress ? navigate("/aadhaarDetails", { state: { tabValue: 6, stepperCount: 5, userDetailId: userDetailId } }) : navigate("/addNewAddress", { state: { tabValue: 7, stepperCount: 5, userDetailId: userDetailId } })
    }

    function isConfirm() {
        console.log("hi")
    }

    async function fetchFromAA() {
        dispatch(setAppLoader(true))
        const resp = await fetchAccountAgg(userDetailId)
        console.log(resp)
        window.location.replace(resp.redirectionurl)
        //dispatch(setAppLoader(false))

    }

    return (

        <Box sx={{ position: "relative" }}>
            {
                !Object.keys(bankDetails)?.length ?
                    <form onSubmit={(e) => onFormSubmit(e)} className='applicationFormContainer' >
                        {
                            error ? <CAlert open={error} message={errorMsg} severity="error" /> : null
                        }
                        {
                            errorMsg === "Data Saved Successfully" ? <CAlert open={true} message={errorMsg} severity="success" /> : null
                        }
                        {
                            !matches &&
                            <Box sx={{ display: "flex" }}>
                                <ArrowBackIosIcon onClick={handleBack} />
                            </Box>
                        }
                        <Grid item xs={12} >
                            {matches && <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "left", fontFamily: "lato", fontWeight: "bold" }}>Income Analysis</Typography>
                                // <Typography color='textColor' variant='subtitle1' sx={{ textAlign: "center" }}>Bank Statement</Typography>
                            }
                        </Grid>
                        <Grid item sx={{ textAlign: "left" }} xs={12} >
                            <Typography color='textColor' variant='Label20' sx={{ mt: 6, textAlign: "left", fontFamily: "lato", }}>Use Account Aggregator or upload a 6 month bank statement pdf</Typography>
                        </Grid>
                        <Grid container spacing={2} justifyContent="center">

                            <Grid item sx={{
                                textAlign: "center", display: "flex",
                                justifyContent: "center",
                            }} xs={6} >
                                <CButton
                                    disabled={disableAA}
                                    handleClick={fetchFromAA}
                                    variant="contained"
                                    name="Proceed With AA"
                                    minWidth={false}
                                    mt={6} // Adjust width as necessary
                                />
                            </Grid>
                        </Grid>
                        {
                            showAASuccess ?
                                <Grid item sx={{ mt: 5 }} >
                                    <img src={success} alt="success" />
                                    <Typography color='textColorFetch' variant='subtitle3' sx={{ mt: 3, ml: 2, mb: 1, textAlign: "left", fontFamily: "lato", fontWeight: "bold" }}>Fetch Successful</Typography>
                                </Grid> : null
                        }
                        <Grid>
                            <Box sx={{ textAlign: "left", fontSize: "18px", bgcolor: '#DBEEFF', color: '#4D4948', p: 2, mt: 3, borderRadius: '8px', fontFamily: "lato" }}>
                                Expect 2 OTPs: one from account aggregator on FLyHi Registered number & another from your bank on number registered with your bank.
                            </Box>
                        </Grid>
                        <Divider sx={{ mt: 5 }} />
                        <Grid item xs={6} container sx={{ pt: 4 }} rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                            {matches ? <Grid sx={{ textAlign: "left" }} item xs={6}>
                                <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                                <Typography color="inputLabelColor" sx={{ textAlign: "left", fontWeight: "bold", fontFamily: "lato" }} align='left' variant="inputLabel">
                                    Statement
                                </Typography>
                                <Button onClick={onFileUpload} fullWidth disabled={disableManual} color="uploadButton" variant="contained" startIcon={<FileUploadIcon />}>
                                    Select File
                                </Button>
                            </Grid> :
                                <Grid sx={{ textAlign: "left" }} item xs={12}>
                                    <input accept="application/pdf" type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={inputRef} />
                                    <Button onClick={onFileUpload} disabled={disableManual} fullWidth color="uploadButton" variant="contained" startIcon={<FileUploadIcon />}>
                                        Upload
                                    </Button>
                                </Grid>
                            }
                            {
                                matches && <Grid item md={6} xs={12}>
                                    <CInput value={password} onChange={setPassword} onBlur={(e) => onPasswordChange(e)} label="Password (if applicable)" disabled={disableManual} />
                                </Grid>
                            }

                            <Grid sx={{ display: "flex", alignItems: "center", gap: "5px" }} item xs={12} md={6}>
                                {fileName &&
                                    <>
                                        <Typography color='textColor' variant='body2' sx={{ textAlign: "left" }}>{fileName}</Typography>
                                        <IconButton
                                            edge="start"
                                            color="inherit"
                                            onClick={deleteFileByURL}
                                            aria-label="close"
                                            disabled={disableManual && disableAA}
                                        >
                                            <CloseIcon color="warning" />
                                        </IconButton>
                                    </>
                                }
                            </Grid>

                            {
                                !matches && <Grid item xs={6} >

                                </Grid>
                            }


                            <Grid sx={{ ml: 2 }}>
                                <Box sx={{ textAlign: "left", fontSize: "18px", bgcolor: '#DBEEFF', color: '#4D4948', p: 2, mt: 3, borderRadius: '8px', fontFamily: "lato", }}>
                                    No Scanned PDF's, No files over 5Mb. Enter PDF password before submitting(if applicable).
                                </Box>
                            </Grid>
                            {
                                !matches && <Grid item md={6} xs={12}>
                                    <CInput value={password} onChange={setPassword} label="Password (if applicable)" />
                                </Grid>
                            }
                        </Grid>
                        {
                            showManualSuccess ?

                                <Grid sx={{ mt: 2 }}>
                                    <img src={success} alt="success" />
                                    <Typography color='textColorFetch' variant='subtitle3' sx={{ mt: 3, ml: 2, mb: 1, textAlign: "left", fontFamily: "lato", fontWeight: "bold" }}>Upload Successful</Typography>
                                </Grid> : null
                        }
                        <div style={{ padding: "20px 0px 0px", display: "flex", flexDirection: "row", justifyContent: `${!matches ? "space-around" : "space-between"}` }}>
                            {matches && <CButton handleClick={() => handleBack()} name="Back" />}
                            <Button
                                type="submit"
                                sx={{ fontStyle: "Lato" }}
                                color="neutral"
                                disabled={disabledSubmit}
                                variant="contained">
                                Save & Submit
                            </Button>
                        </div>
                    </form > :
                    <div>
                        {
                            !matches &&
                            <Box sx={{ p: 4, display: "flex" }}>
                                <ArrowBackIosIcon onClick={() => handleBack()} />
                            </Box>
                        }

                        <Box sx={{ position: "absolute", top: "100px", width: { sm: "384px", xs: "300px" }, left: { sm: "25%", xs: "12%" }, right: { sm: "0%", xs: "12%" } }}>
                            <CBankData onSubmit={onSubmit} bankDetails={bankDetails} handleBack={handleBack} />
                        </Box>
                    </div>
            }
            {showPopUp && <CDialogue open={showPopUp} setOpen={setShowPopUp} isConfirm={isConfirm} message={message} isOkButton={true} />}
        </Box >
    )
}